import { graphql } from "gatsby"
import React from "react"
import Masonry from "react-masonry-css"
import { SRLWrapper } from "simple-react-lightbox"
import Banner from "../../components/Banner"
import Layout from "../../components/core/Layout"
import SEO from "../../components/core/SEO"
import Images from "../../components/Images"



type BostonGalleryProps = {
    data: {
        images: {
            edges: Array<{ node: { childImageSharp: any } }>
        }
    }
}

const BostonBuoysPage: React.FC<BostonGalleryProps> = ({ data }) => (
    <Layout activePage="projects">
        <SEO title="Community Clay Project" />
        <Banner>
            <p>
                <b>This project is now completed.</b><br />
                Thank you to everyone that supported and took part in this project.
                The completed piece will be available to view next to St Botolphs Footbridge in Boston.<br />
                <span className="timescale">This project ran from March 2021 to July 2021.</span>
            </p>
        </Banner>
        <h1 className="page-title">Boston Buoys</h1>
        <div className="community-clay__container">
            <div>
                <p>
                    We were commissioned by Carrie Reichardt to produce ceramic fish to be incorporated into her artwork "By Sea" for the Boston Buoys project.
                    The idea was that most of these ceramic fish were to be designed and made by members of the local community and schoolchildren.
                </p>
                <p>
                    The restrictions surrounding the COVID-19 pandemic meant that we were unable to deliver any face-to-face workshops.
                    In line with these restrictions, we decided to produce kits for Year 4 children in a local school where they could design and make these fish.
                </p>
                <p>
                    We produced and delivered over 50 clay kits, which each included enough clay for at least 1 fish, templates and some simple tools.
                    Full and visual instructions were produced for people to follow along at school.
                </p>
                <p>
                    After the kits had been completed, we collected, bisque fired, glazed in blue-green colours and fired once again to produce the fish for use on the artwork (see photo).
                </p>
                <p>These fish were then included as part of a mosaic in Carrie's artwork. Below are a few photos of the finished artwork, which is on display in Boston (next to St Botolphs Footbridge).</p>
            </div>
            <SRLWrapper>
                <Images image="bostonbuoysfishlarge" />
            </SRLWrapper>
        </div>
        <SRLWrapper>
            <Masonry className="masonry-grid" columnClassName="masonry-grid__column" breakpointCols={5}  >
                {data.images.edges.map(({ node }) => <img srcSet={node.childImageSharp.full.srcSet} src={node.childImageSharp.full.src} />)}
            </Masonry>
        </SRLWrapper>
    </Layout>
)


export const query = graphql`query ImagesForBostonBuoysGallery {
  images: allFile(
    filter: {relativeDirectory: {eq: "projects/boston-buoys/finished-product"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        childImageSharp {
          thumb: gatsbyImageData(
            width: 400
            height: 400
            quality: 80
            layout: CONSTRAINED
          )
          full: fluid(maxWidth: 2048) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`

export default BostonBuoysPage
